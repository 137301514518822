import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-secondary text-white p-4 text-center">
            <p>&copy; 2024 Cracckami L'ega</p>
        </footer>
    );
}

export default Footer;
