import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaBars, FaTimes, FaHome, FaUser, FaTrophy, FaImages, FaFutbol } from 'react-icons/fa';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-secondary p-4 fixed w-full z-20 top-0 shadow-md transition duration-500 ease-in-out">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/" className="text-white cursor-pointer">
                    <div className="text-white text-lg font-bold cursor-pointer" style={{ fontFamily: 'Daggersquare' }}>
                        CRACCKAMI L'EGA
                    </div>
                </Link>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-white">
                        {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>
                <div className="hidden md:flex md:items-center md:space-x-4">
                    <Link to="/"
                          className="text-white cursor-pointer flex items-center transition duration-500 ease-in-out transform hover:scale-105">
                        <FaHome size={24} className="mr-2"/>
                    </Link>
                    <Link to="/about-us"
                          className="text-white cursor-pointer flex items-center transition duration-500 ease-in-out transform hover:scale-105">
                        <FaUser size={24} className="mr-2"/>
                    </Link>
                    <Link to="/albo-doro"
                          className="text-white cursor-pointer flex items-center transition duration-500 ease-in-out transform hover:scale-105">
                        <FaTrophy size={24} className="mr-2"/>
                    </Link>
                    <Link to="/photo-gallery"
                          className="text-white cursor-pointer flex items-center transition duration-500 ease-in-out transform hover:scale-105">
                        <FaImages size={24} className="mr-2"/>
                    </Link>
                    <a href="https://leghe.fantacalcio.it/craccckami-l-ega" target="_blank" rel="noopener noreferrer"
                       className="text-white flex items-center transition duration-500 ease-in-out transform hover:scale-105">
                        <FaFutbol size={24} className="mr-2"/>
                    </a>
                    <a href="https://www.instagram.com/crackami_l_ega/" target="_blank" rel="noopener noreferrer"
                       className="text-white flex items-center transition duration-500 ease-in-out transform hover:scale-105">
                        <FaInstagram size={24} className="mr-2"/>
                    </a>
                </div>
            </div>
            {isOpen && (
                <>
                    <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={toggleMenu}></div>
                    <div
                        className={`fixed inset-y-0 left-0 w-64 bg-secondary bg-opacity-95 flex flex-col items-start justify-center p-6 z-20 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 shadow-lg`}>
                        <Link to="/"
                              className="text-white text-2xl cursor-pointer flex items-center w-full border-b border-white py-2"
                              onClick={toggleMenu}>
                            <FaHome size={32}/> <span className="ml-2">Home</span>
                        </Link>
                        <Link to="/about-us"
                              className="text-white text-2xl cursor-pointer flex items-center w-full border-b border-white py-2"
                              onClick={toggleMenu}>
                            <FaUser size={32}/> <span className="ml-2">I L'eghisti</span>
                        </Link>
                        <Link to="/albo-doro"
                              className="text-white text-2xl cursor-pointer flex items-center w-full border-b border-white py-2"
                              onClick={toggleMenu}>
                            <FaTrophy size={32}/> <span className="ml-2">Albo D'Oro</span>
                        </Link>
                        <Link to="/photo-gallery"
                              className="text-white text-2xl cursor-pointer flex items-center w-full border-b border-white py-2"
                              onClick={toggleMenu}>
                            <FaImages size={32}/> <span className="ml-2">Photo Gallery</span>
                        </Link>
                        <a href="https://leghe.fantacalcio.it/craccckami-l-ega" target="_blank"
                           rel="noopener noreferrer" className="text-white text-2xl flex items-center w-full border-b border-white py-2">
                            <FaFutbol size={32}/> <span className="ml-2">Cracckami L'ega</span>
                        </a>
                        <a href="https://www.instagram.com/crackami_l_ega/" target="_blank" rel="noopener noreferrer"
                           className="text-white text-2xl flex items-center w-full py-2">
                            <FaInstagram size={32}/> <span className="ml-2">Anziano Cane</span>
                        </a>
                    </div>
                </>
            )}
        </nav>
    );
}

export default Navbar;
