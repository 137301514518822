import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import AboutUs from './components/AboutUs';
import AlboDoro from './components/AlboDoro';
import PhotoGallery from './components/PhotoGallery';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import ScrollToTop from "./components/ScrollToTop";
import './App.css';

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <div className="app-container">
                <Navbar />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={
                            <>
                                <LandingPage />
                                <AboutUs isCarousel={true} />
                            </>
                        } />
                        <Route path="/about-us" element={<AboutUs isCarousel={false} />} />
                        <Route path="/albo-doro" element={<AlboDoro />} />
                        <Route path="/photo-gallery" element={<PhotoGallery />} />
                        <Route path="*" element={<LandingPage />} /> {/* Fallback per percorsi non definiti */}
                    </Routes>
                </div>
                <Footer />
            </div>
            <ScrollToTopButton />
        </Router>
    );
}

export default App;
