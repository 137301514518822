import React from 'react';
import { Link } from 'react-router-dom';
import './CallToAction.css';


const CallToAction = () => {
    return (
        <div className="cta-container bg-lightGray">
            <h2 className="cta-title">Dove Anziani Cani e Fanali si incontrano</h2>
            <div className="cta-description">
                <p>
                    Dai fumi dell’alcol, da una vita di
                    amicizia e di attività ricreative forse ritenute illegali da qualche autorità, questa L'ega è la
                    nostra casa. Sotto l'occhio
                    vigile
                    del Garante – amico, fratello e giudice imparziale – siamo pronti a scrivere un altro
                    capitolo della nostra storia.
                </p><br/>
                <p>
                    Abbiamo avuto molte guide illuminate - talvolta coadiuvate da un luminoso Fanale impiantato in
                    fronte -
                    condurci in avventure memorabili, dai campi sacri di Varmo
                    all'Antico Granaio, fino ai pascoli di Broz.
                </p><br/>
                <p>
                    Mentre ci prepariamo ad un nuovo cracckaminoso anno di gioie, pianti, droghe e fanali, vogliamo qui
                    rivivere e ricordare i momenti e le esperienze vissute assieme.
                </p><br/><p>Viva la L'Ega, viva i l'eghisti!
            </p>
            </div>
            <Link
                to="/photo-gallery"
                className="cta-button"
            >
                CRACCKAMI GALLERY
            </Link>
        </div>
    );
}

export default CallToAction;
