import React from 'react';
import './LandingPage.css';
import backgroundImage from '../assets/videos/CrackLand.jpg';
import CallToAction from './CallToAction';

const LandingPage = () => {
    return (
        <div className="bg-lightGray">
            <div className="landing-page-container" style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="overlay">
                    <div className="text-center">
                        <h1 className="landing-title">Welcome to Cracckami L'ega</h1>
                        <h2 className="landing-subtitle">Una L'ega italiana</h2>
                    </div>
                </div>
            </div>
            <CallToAction/>
        </div>
    );
}

export default LandingPage;
