import React, { useState } from 'react';
import { FaTrophy, FaShieldAlt, FaTint, FaRegLightbulb } from 'react-icons/fa';

const winners = [
    { year: 2024, coppaCrack: 'Brenditos', serieCrack: 'Fiasco da Gama', lacrimaCrack: 'SudSoundSystemone', fanaleCrack: 'Le Spose Bambine' },
    { year: 2023, coppaCrack: '', serieCrack: 'Monna Fortezza', lacrimaCrack: 'SudSoundSystemone', fanaleCrack: 'F.C. Piracy' },
    { year: 2022, coppaCrack: '', serieCrack: 'SudSoundSystemone', lacrimaCrack: 'SudSoundSystemone', fanaleCrack: 'F.C. Piracy' },
    { year: 2021, coppaCrack: '', serieCrack: 'Le Spose Bambine', lacrimaCrack: 'SudSoundSystemone', fanaleCrack: 'Monna Fortezza' },
    { year: 2020, coppaCrack: '', serieCrack: 'Levocidelsole', lacrimaCrack: 'SudSoundSystemone', fanaleCrack: 'Brenditos' },
    { year: 2019, coppaCrack: '', serieCrack: 'The Curliest', lacrimaCrack: 'SudSoundSystemone', fanaleCrack: 'Monna Fortezza' }
    // aggiungere altri anni, sistemare nomi vincitori
];

const AlboDoro = () => {
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const getFilteredWinners = () => {
        if (selectedCategory) {
            return winners.map(winner => ({
                year: winner.year,
                name: winner[selectedCategory]
            }));
        }

        return winners.filter(winner => {
            return selectedYear ? winner.year === parseInt(selectedYear) : true;
        });
    };

    const filteredWinners = getFilteredWinners();

    return (
        <div className="bg-lightGray p-8 pt-20 min-h-screen">
            <h2 className="text-3xl font-bold mb-6 text-darkGray" style={{ fontFamily: 'Daggersquare' }}>Albo d'Oro</h2>

            <div className="mb-4 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="p-2 rounded border transition duration-300 ease-in-out hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-primary-50"
                >
                    <option value="">Tutti gli anni</option>
                    {[...new Set(winners.map(winner => winner.year))].map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>

                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="p-2 rounded border transition duration-300 ease-in-out hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-primary-50"
                >
                    <option value="">Tutti i trofei</option>
                    <option value="serieCrack">Serie Crack</option>
                    <option value="coppaCrack">Coppa Crack</option>
                    <option value="lacrimaCrack">Lacrima D'Oro</option>
                    <option value="fanaleCrack">Fanale</option>
                </select>
            </div>

            <div className="space-y-4">
                {selectedCategory ? (
                    filteredWinners.map((winner, index) => (
                        <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
                            <h3 className="text-xl font-bold text-zinc-950">{winner.year}</h3>
                            <p className="text-zinc-950">{winner.name}</p>
                        </div>
                    ))
                ) : (
                    filteredWinners.map((winner, index) => (
                        <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
                            <h3 className="text-xl font-bold text-zinc-950">{winner.year}</h3>
                            <div className="flex items-center mt-2">
                                <FaShieldAlt className="text-secondary mr-2" />
                                <p className="text-darkGray">Serie Crack: {winner.serieCrack}</p>
                            </div>
                            <div className="flex items-center mt-2">
                                <FaTrophy className="text-secondary mr-2" />
                                <p className="text-darkGray">Coppa Crack: {winner.coppaCrack}</p>
                            </div>
                            <div className="flex items-center mt-2">
                                <FaTint className="text-secondary mr-2" />
                                <p className="text-darkGray">Lacrima D'Oro: {winner.lacrimaCrack}</p>
                            </div>
                            <div className="flex items-center mt-2">
                                <FaRegLightbulb className="text-secondary mr-2" />
                                <p className="text-darkGray">Fanale: {winner.fanaleCrack}</p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default AlboDoro;
