import React, {useState} from 'react';
import './PhotoGallery.css';
import LazyLoad from 'react-lazyload';

import img1 from '../assets/images/photogallery/29A_01876.jpg';
import img2 from '../assets/images/photogallery/000507510004.jpg';
import img3 from '../assets/images/photogallery/000507510013.jpg';
import img4 from '../assets/images/photogallery/000507510008.jpg';
import img5 from '../assets/images/photogallery/000507510021.jpg';
import img6 from '../assets/images/photogallery/_14_01860.jpg';
import img7 from '../assets/images/photogallery/_16_01862.jpg'
import img8 from '../assets/images/photogallery/_17_01863.jpg';
import img9 from '../assets/images/photogallery/__0_01819.jpg';
import img10 from '../assets/images/photogallery/__8_01893.jpg';
import img11 from '../assets/images/photogallery/giuze.jpg';
import img12 from '../assets/images/photogallery/giuze_1.jpg';
import img13 from '../assets/images/photogallery/giuze_2.jpg';
import img14 from '../assets/images/photogallery/giuze_3.jpg';
import img15 from '../assets/images/photogallery/giuze_4.jpg';
import img16 from '../assets/images/photogallery/giuze_5.jpg';
import img17 from '../assets/images/photogallery/giuze_6.jpg';
import img18 from '../assets/images/photogallery/giuze_7.jpg';
import img19 from '../assets/images/photogallery/giuze_8.jpg';
import img20 from '../assets/images/photogallery/giuze_9.jpg';
import img21 from '../assets/images/photogallery/giuze_10.jpg';
import img22 from '../assets/images/photogallery/giuze_11.jpg';
import img23 from '../assets/images/photogallery/giuze_12.jpg';
import img24 from '../assets/images/photogallery/giuze_13.jpg';
import img25 from '../assets/images/photogallery/giuze_14.jpg';
import img26 from '../assets/images/photogallery/giuze_15.jpg';
import img27 from '../assets/images/photogallery/giuze_16.jpg';
import img28 from '../assets/images/photogallery/giuze_17.jpg';
import img29 from '../assets/images/photogallery/OMDX0160.JPG';
import img30 from '../assets/images/photogallery/OMDX0286.JPG';
import img31 from '../assets/images/photogallery/OMDX0302.JPG';
import img32 from '../assets/images/photogallery/OMDX0352.JPG';
import a1 from '../assets/images/photogallery/a1.JPG';
import a2 from '../assets/images/photogallery/a2.JPG';
import a3 from '../assets/images/photogallery/a3.JPG';
import a4 from '../assets/images/photogallery/a4.JPG';
import a5 from '../assets/images/photogallery/a5.JPG';
import a6 from '../assets/images/photogallery/a6.JPG';
import a7 from '../assets/images/photogallery/a7.JPG';
import a8 from '../assets/images/photogallery/a8.JPG';
import a9 from '../assets/images/photogallery/a9.JPG';
import a10 from '../assets/images/photogallery/a10.JPG';
import a11 from '../assets/images/photogallery/a11.JPG';
import a12 from '../assets/images/photogallery/a12.JPG';
import a13 from '../assets/images/photogallery/a13.JPG';
import a14 from '../assets/images/photogallery/a14.JPG';
import a15 from '../assets/images/photogallery/a15.JPG';
import a16 from '../assets/images/photogallery/a16.JPG';
import a17 from '../assets/images/photogallery/a17.JPG';
import a18 from '../assets/images/photogallery/a18.JPG';
import a19 from '../assets/images/photogallery/a19.JPG';
import a20 from '../assets/images/photogallery/a20.JPG';
import a21 from '../assets/images/photogallery/a21.JPG';
import a22 from '../assets/images/photogallery/a22.JPG';
import a23 from '../assets/images/photogallery/a23.JPG';
import a24 from '../assets/images/photogallery/a24.JPG';
import a25 from '../assets/images/photogallery/a25.JPG';
import a26 from '../assets/images/photogallery/a26.JPG';
import a27 from '../assets/images/photogallery/a27.JPG';
import a28 from '../assets/images/photogallery/a28.JPG';
import a29 from '../assets/images/photogallery/a29.JPG';
import a30 from '../assets/images/photogallery/a30.JPG';
import a31 from '../assets/images/photogallery/a31.JPG';
import a32 from '../assets/images/photogallery/a32.JPG';
import a33 from '../assets/images/photogallery/a33.JPG';
import a34 from '../assets/images/photogallery/a34.JPG';
import a35 from '../assets/images/photogallery/a35.jpg';
import a36 from '../assets/images/photogallery/a36.jpg';
import a37 from '../assets/images/aboutus/Lezimie.jfif';

const images = [
    {id: 1, src: img17, orientation: 'landscape'},
    {id: 2, src: img16, orientation: 'landscape'},
    {id: 3, src: img12, orientation: 'landscape'},
    {id: 4, src: img11, orientation: 'landscape'},
    {id: 5, src: img32, orientation: 'landscape'},
    {id: 6, src: img8, orientation: 'landscape'},
    {id: 7, src: img18, orientation: 'landscape'},
    {id: 8, src: img6, orientation: 'landscape'},
    {id: 9, src: img31, orientation: 'landscape'},
    {id: 10, src: img13, orientation: 'landscape'},
    {id: 11, src: img14, orientation: 'landscape'},
    {id: 12, src: img9, orientation: 'landscape'},
    {id: 13, src: img15, orientation: 'landscape'},
    {id: 14, src: img30, orientation: 'landscape'},
    {id: 15, src: a8, orientation: 'landscape'},
    {id: 16, src: img7, orientation: 'landscape'},
    {id: 17, src: img19, orientation: 'landscape'},
    {id: 18, src: img20, orientation: 'landscape'},
    {id: 19, src: img21, orientation: 'landscape'},
    {id: 20, src: img22, orientation: 'landscape'},
    {id: 21, src: img23, orientation: 'landscape'},
    {id: 22, src: img24, orientation: 'landscape'},
    {id: 23, src: img25, orientation: 'landscape'},
    {id: 24, src: img26, orientation: 'landscape'},
    {id: 25, src: img27, orientation: 'landscape'},
    {id: 26, src: img28, orientation: 'landscape'},
    {id: 27, src: img5, orientation: 'landscape'},
    {id: 28, src: img1, orientation: 'portrait'},
    {id: 29, src: img2, orientation: 'portrait'},
    {id: 30, src: img3, orientation: 'portrait'},
    {id: 31, src: img10, orientation: 'portrait'},
    {id: 32, src: img29, orientation: 'portrait'},
    { id: 33, src: a1, orientation: 'portrait' },
    { id: 34, src: a2, orientation: 'landscape' },
    { id: 35, src: a3, orientation: 'landscape' },
    { id: 36, src: a4, orientation: 'portrait' },
    { id: 37, src: a5, orientation: 'landscape' },
    { id: 38, src: a6, orientation: 'portrait' },
    { id: 39, src: a7, orientation: 'landscape' },
    { id: 40, src: img4, orientation: 'landscape' },
    { id: 41, src: a9, orientation: 'landscape' },
    { id: 42, src: a10, orientation: 'landscape' },
    { id: 43, src: a11, orientation: 'landscape' },
    { id: 44, src: a12, orientation: 'portrait' },
    { id: 45, src: a13, orientation: 'landscape' },
    { id: 46, src: a14, orientation: 'landscape' },
    { id: 47, src: a15, orientation: 'portrait' },
    { id: 48, src: a16, orientation: 'landscape' },
    { id: 49, src: a17, orientation: 'landscape' },
    { id: 50, src: a18, orientation: 'landscape' },
    { id: 51, src: a19, orientation: 'landscape' },
    { id: 52, src: a20, orientation: 'landscape' },
    { id: 53, src: a21, orientation: 'landscape' },
    { id: 54, src: a22, orientation: 'landscape' },
    { id: 55, src: a23, orientation: 'landscape' },
    { id: 56, src: a24, orientation: 'landscape' },
    { id: 57, src: a25, orientation: 'landscape' },
    { id: 58, src: a26, orientation: 'landscape' },
    { id: 59, src: a27, orientation: 'landscape' },
    { id: 60, src: a28, orientation: 'portrait' },
    { id: 61, src: a29, orientation: 'portrait' },
    { id: 62, src: a30, orientation: 'portrait' },
    { id: 63, src: a31, orientation: 'portrait' },
    { id: 64, src: a32, orientation: 'landscape' },
    { id: 65, src: a33, orientation: 'landscape' },
    { id: 66, src: a34, orientation: 'landscape' },
    { id: 67, src: a35, orientation: 'landscape' },
    { id: 68, src: a36, orientation: 'landscape' },
    { id: 69, src: a37, orientation: 'landscape' }
];




const PhotoGallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    const downloadImage = (src) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="photo-gallery bg-lightGray p-8 pt-20 min-h-screen">
            {/* <h2 className="text-3xl font-bold mb-6 text-darkGray">CRACCKAMI GALLERY</h2> */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {images.map(image => (
                    <LazyLoad key={image.id} height={300} offset={100} once>
                        <div className="image-container">
                            <img
                                src={image.src}
                                alt=""
                                className={`w-full ${image.orientation === 'landscape' ? 'h-80 object-cover' : 'h-80 object-contain'} rounded-lg cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105`}
                                onClick={() => openImage(image)}
                            />
                        </div>
                    </LazyLoad>
                ))}
            </div>

            {selectedImage && (
                <div className="modal-overlay" onClick={closeImage}>
                    <div className="modal-content">
                        <img src={selectedImage.src} alt="" className="modal-image" />
                        <div className="modal-buttons">
                            <button onClick={() => downloadImage(selectedImage.src)} className="download-btn">Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PhotoGallery;
