import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import TheCurliest from '../assets/images/aboutus/TheCurliest.jfif';
import Levocidelsole from '../assets/images/aboutus/Levocidelsole.jpg';
import Cevapcici from '../assets/images/photogallery/a21.JPG';
import Lezimie from '../assets/images/photogallery/a37.jpg';
import Brenditos from '../assets/images/aboutus/Brenditos.jpg';
import Monnafortezza from '../assets/images/aboutus/Monna.JPG';
import FiascoDaGama from '../assets/images/aboutus/FiascoDaGama.jfif';
import Piracy from '../assets/images/aboutus/Piracy.png';
import SudSoundSystemone from '../assets/images/photogallery/OMDX0286.JPG';
import Tremendissima from '../assets/images/aboutus/Tremendissima.jfif';
import a4 from '../assets/images/aboutus/a4gar.jpg';
import './AboutUs.css';

import BrenditosAudio from '../assets/audio/Brenditos.mp3';
import TheCurliestAudio from '../assets/audio/Curliest.mp3';
import LeVociAudio from '../assets/audio/LeVocidelSole.mp3';
import FiascoAudio from '../assets/audio/Fiasco da Gama.mp3';
import LezimieAudio from '../assets/audio/Lezimie.mp3';
import SudSoundAudio from '../assets/audio/SudSoundSystemone.mp3';
import PiracyAudio from '../assets/audio/Piracy.mp3';
import CevapciciAudio from '../assets/audio/Cevapcici.mp3';
import TremendissimaAudio from '../assets/audio/Tremendissima Mulata.mp3';
import MonnaAudio from '../assets/audio/Monna Fortezza.mp3';

const members = [
    {
        name: 'The Curliest',
        image: TheCurliest,
        description: '',
        audio: TheCurliestAudio,
    },
    {
        name: 'Levocidelsole',
        image: Levocidelsole,
        description: '',
        audio: LeVociAudio,
    },
    {
        name: 'Fiasco da Gama',
        image: FiascoDaGama,
        description: '',
        audio: FiascoAudio,
    },
    {
        name: 'Lezimie',
        image: Lezimie,
        description: '',
        audio: LezimieAudio,
    },
    {
        name: 'Brenditos',
        image: Brenditos,
        description: '',
        audio: BrenditosAudio,
    },
    {
        name: 'SudSoundSystemone',
        image: SudSoundSystemone,
        description: '',
        audio: SudSoundAudio,
    },
    {
        name: 'F.C. Piracy',
        image: Piracy,
        description: '',
        audio: PiracyAudio,
    },
    {
        name: 'Monna Fortezza',
        image: Monnafortezza,
        description: '',
        audio: MonnaAudio,
    },
    {
        name: 'LeSposeBambine',
        image: Cevapcici,
        description: '',
        audio: CevapciciAudio,
    },
    {
        name: 'Tremendissima Mulata',
        image: Tremendissima,
        description: '',
        audio: TremendissimaAudio,
    },
    {
        name: 'Il Garante',
        image: a4,
        description: 'Imparziale mano della Giustizia della Cracckami',
    },
];

const AboutUsCarousel = ({ handlePlay }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="carousel-container bg-lightGray">
            <Slider {...settings}>
                {members.map((member, index) => (
                    <div key={index} className="p-4">
                        <div className="bg-white p-4 rounded-lg shadow-lg">
                            <img src={member.image} alt={member.name} className="w-full h-72 object-cover rounded-t-lg" />
                            <div className="mt-4">
                                <h3 className="text-xl font-bold text-darkGray">{member.name}</h3>
                                <p>{member.description}</p>
                                {/* {member.audio && (
                                    <>
                                        <p className="italic text-secondary mt-2">Picciella 2023/24</p>
                                        <audio
                                            className="custom-audio-player"
                                            controls
                                            onPlay={(e) => handlePlay(e.target)}
                                        >
                                            <source src={member.audio} type="audio/mp3" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </>
                                )} */}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

const AboutUsList = ({ handlePlay }) => {
    useEffect(() => {
        const audioElements = document.querySelectorAll('audio');
        audioElements.forEach((audio) => {
            audio.volume = 0.6;
        });
    }, []);

    return (
        <div className="bg-lightGray p-8 pt-20 min-h-screen">
            <h2 className="text-3xl font-bold mb-6 text-darkGray">I L'Eghisti</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {members.map((member, index) => (
                    <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
                        <img src={member.image} alt={member.name} className="w-full h-72 object-cover rounded-t-lg" />
                        <div className="mt-4">
                            <h3 className="text-xl font-bold text-darkGray">{member.name}</h3>
                            <p>{member.description}</p>
                            {/* }{member.audio && (
                                <>
                                    <p className="italic text-secondary mt-2">Picciella 2023/24</p>
                                    <audio
                                        className="custom-audio-player"
                                        controls
                                        onPlay={(e) => handlePlay(e.target)}
                                    >
                                        <source src={member.audio} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </>
                            )} */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const AboutUs = ({ isCarousel }) => {
    const [currentAudio, setCurrentAudio] = useState(null);

    const handlePlay = (audioElement) => {
        if (currentAudio && currentAudio !== audioElement) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
        }
        setCurrentAudio(audioElement);
    };

    return isCarousel ? <AboutUsCarousel handlePlay={handlePlay} /> : <AboutUsList handlePlay={handlePlay} />;
};

export default AboutUs;
